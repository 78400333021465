.contact {
    display: flex;
    /* align-items: flex-start; */
    gap: 8px;
}

.contact-details {
    flex: auto;
}

.contact-decoration {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin: 16px;
}
  
.contact-name {
    font-size: 18px;
    font-weight: 500;
    text-align: start;
}

.contact-name:hover {
    cursor: pointer;
    color: var(--accent-color-faded);
}
  
.phone-email {
    margin-bottom: 8px;
    display: inline-block;
    
    font-size: 14px;
    font-style: italic;
    text-align: start;
}
  
.contact > .svg {
    align-self: flex-start;
}