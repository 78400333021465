.pin {
  width: 38px;
  height: 50px;
  border: 1px solid #9a937d;
  border-radius: 10px;
  font-size: 12px;
  text-align: center;
  align-content: space-evenly;
}

.pin-inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
}
