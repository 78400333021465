.profile-page-illustration {
  max-width: 300px;
}

.profile-page-add {
  border-radius: 24px;
  height: 48px;
}

.profile-page-no-profile-message {
  gap: 8px;
}

.profile-page-no-profile-message p {
  margin-right: auto;
}