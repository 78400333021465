.tag {
  height: 24px;
  padding: 0px 8px;
  padding-top: 1px;
  border: 1px solid var(--accent-color-faded);
  border-radius: 12px;
  box-sizing: border-box;
  background-color: var(--accent-color-extra-faded);
  color: var(--accent-color);

  transition: var(--transition-time-fast);
  cursor: pointer;

  font-size: 14px;
}

.tag:hover {
  border: 1px solid var(--accent-color);
  background-color: var(--accent-color-faded);
}
