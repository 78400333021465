.trips-history-page-title-row {
  display: flex;
  justify-content: space-between;
  min-height: 48px;
}

.trips-history-page-title-row > button {
  align-items: center;
  border-radius: 24px;
  display: flex;
  height: 48px;
  justify-content: center;
  padding: 0;
  width: 48px;
}

.trips-history-page-illustration {
  max-width: 300px;
}

.trips-history-page-no-trip-message {
  gap: 8px;
}

.trips-history-page-no-trip-message p {
  margin-right: auto;
}
