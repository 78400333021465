.page-home-link {
  color: inherit;
  font-weight: 500;
  text-decoration: none;
}

.page-sidenav-link {
  display: flex;
  gap: 12px;
}

a.page-sidenav-link {
  color: inherit;
  text-decoration: none;
}

.page-sidenav-link > p {
  font-weight: 600;
  margin: 0;
  padding: 0;
}
