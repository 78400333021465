.search {
    border: 0.5px solid var(--color-accent-1);
    border-radius: 15px;
    padding: 5px;
    text-align: center;
}

.search:focus {
    outline: none !important;
}

::placeholder {
    color: var(--color-accent-3);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}