.location-review-page-map {
    height: 50vh;
  }
  /* 
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  } */
  
  .Overlay {
    position: fixed;
    bottom: -150vh;
    background-color: #fff;
    width: 100%;
    height: 60%;
    box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
    left: 0;
    padding: 0 12px 12px;
    transition: all 0.6s ease-out;
    z-index: 10;
    overflow: auto;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
  }
  
  .Overlay h3 {
    margin-top: 1rem;
    text-align: center;
  }
  
  .Overlay.Show {
    bottom: 0;
    box-sizing: border-box;
  }
  
  .Close {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 10px;
    background-color: transparent;
    border: 0;
    font-size: 18px;
  }
  
  .location-review-page-buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .location-review-page-buttons > a {
    color: inherit;
    height: 48px;
    text-decoration: none;
    width: 100%;
  }
  
  .location-review-page-buttons a > button {
    border-radius: 32px;
    height: 48px;
    width: 100%;
  }
  
  .location-review-page-illustration {
    max-width: 300px;
  }
  