.footer-spacer {
  flex-grow: 1;
}

.footer {
  background-color: var(--color-bg-2);
  border-top: 1px solid var(--color-accent-1);
  bottom: 0px;
  box-sizing: border-box;
  bottom: 0px;
  min-height: 128px;
  width: 100%;
}
