.itinerary-button {
    font-family: Helvetica, sans-serif;
    font-size: 15px;
    text-align: center;
    border-color: #9a937d;
    border-style: solid;
    border-width: 1px;
    background-color: #d1cbb9;
    color: #564c47;
    border-radius: 8px;
    padding: 4px 40px;
}


.trip-select {
    width: 80%;
    font-family: Helvetica, sans-serif;
    color: #9a937d;
    padding: 10px 6px;
    font-size: 15px;
    border-width: 1px;
    border-color: #564c47;
    border-radius: 3px;
}


.time-header {
    font-size: 18px;
    font-weight: bold;
}


.current-events {
    background-color: #ece6cf;
    color: #544d45;
    width: 100%;
    border-radius: 5px;
    padding: 2px 4px;
}

.event-listing {
    padding-left: 10px;
}

.event-details {
    font-size: 14px;
    padding-left: 30px;
}