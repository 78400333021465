.trip {
  display: flex;
  /* align-items: flex-start; */
  gap: 8px;
}

.trip-decoration {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.trip-decoration::after {
  content: '';

  position: relative;
  min-height: calc(100% - 16px);
  border-left: 2px solid var(--accent-color);

  flex-grow: 1;
}

.trip-name {
  font-size: 18px;
  font-weight: 500;
  text-align: start;
}

.trip-location-date {
  margin-bottom: 8px;
  display: inline-block;
  
  font-size: 14px;
  font-style: italic;
  text-align: start;
}

.trip > .svg {
  align-self: flex-start;
}
