.home-page-illustration {
  max-width: 300px;
}

.home-page-trips-banner {
  gap: 8px;
}

.home-page-trips-banner a {
  margin-left: auto;
  text-decoration: none;
}

.home-page-trips-banner button {
  align-items: center;
  display: flex;
  gap: 4px;
}

.home-page-trips-banner button p {
  white-space: nowrap;
}

.home-page-manage-buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.home-page-manage-buttons > a {
  color: inherit;
  height: 64px;
  text-decoration: none;
  width: 100%;
}

.home-page-manage-buttons a > button {
  border-radius: 32px;
  height: 64px;
  width: 100%;
}
