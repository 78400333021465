.form-text-input {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.form-text-input label {
  font-size: 12px;
}

.form-text-input input[type="text"] {
  width: 100%;
  height: 32px;
  /* flex-grow: 1; */
  padding: 0px 8px;
  border: 1px solid var(--accent-color-faded);
  border-radius: 4px;
  box-sizing: border-box;
  color: inherit;

  transition: var(--transition-time-fast);
}

.form-text-input input[type="text"]:hover {
  border: 1px solid var(--accent-color);
}

.form-text-input input[type="text"]:focus {
  outline: none;
  border: 1px solid var(--accent-color);
}

.form-text-input input[type="text"]::placeholder {
  color: var(--accent-color-faded);
}
