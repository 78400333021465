.contacts-page-illustration {
  max-width: 300px;
}

.contacts-page-add {
  border-radius: 24px;
  height: 48px;
}

.contacts-page-no-contact-message {
  gap: 8px;
}

.contacts-page-no-contact-message p {
  margin-right: auto;
}