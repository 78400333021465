.error-box {
  border: 2px solid #9a937d;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 95%;
  /* height: 20%; */
}

/* h2 {
  text-align: center;
  margin-top: 1%;
}

hr {
  margin: 3% 0 3% 0;
}

p {
  margin-bottom: 1%;
  text-align: center;
} */
