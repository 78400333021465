.current-trip-page-buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.current-trip-page-buttons > a {
  color: inherit;
  height: 48px;
  text-decoration: none;
  width: 100%;
}

.current-trip-page-buttons a > button {
  border-radius: 32px;
  height: 48px;
  width: 100%;
}
