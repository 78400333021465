:root {
  --color-accent-1: rgb(228, 222, 205);
  --color-accent-2: #d1cbb9;
  --color-accent-3: #9A937D;
  --color-accent-3-filter: invert(63%) sepia(11%) saturate(545%) hue-rotate(8deg) brightness(91%) contrast(88%);
  --color-bg-1: rgb(255, 255, 255);
  --color-bg-2: rgb(255, 255, 255);

  --height-nav: 64px;
  --width-sidenav: min(80vw, calc(100vw - 64px));
  --gap-x-regular: 12px;
  --gap-y-regular: 24px;
  --gap-y-large: 32px;

  --speed-fast: 150ms;
  --speed-regular: 250ms;
  --speed-slow: 350ms;





  /* Color */
  /* --accent-color: #9A937D;
  --accent-color-faded: #E3DECE;
  --accent-color-extra-faded: #f7f3e4;
  --accent-color-extra-faded-translucent: rgba(247, 243, 228, 0.75);
  --bg-color: #FFFFFF;
  --bg-color-translucent: rgba(255, 255, 255, 0.15);

  --acrylic-accent-color: rgba(247, 243, 228);
  --acrylic-accent-color-translucent: rgba(247, 243, 228, 0.75);
  --acrylic-accent-color-translucent-dark: rgba(227, 222, 206, 0.75); */

  /* Size */
  /* --navbar-height: 64px;
  --sidenav-width: calc(100vw - 64px);

  --side-gap: 16px;
  --side-gap-large: 16px;
  --side-gap-xlarge: 32px; */

  /* Speed */
  /* --transition-time-fast: 0.15s;
  --transition-time-regular: 0.5s; */
}

@media only screen and (min-width: 768px) {
  :root {
    --width-sidenav: 256px;
    --gap-x-regular: 16px;



    /* Size */
    --sidenav-width: 256px;

    --side-gap: 32px;
    --side-gap-large: 32px;
    --side-gap-xlarge: 64px;
  }
}

@media only screen and (min-width: 1200px) {
  :root {
    --gap-x-regular: 24px;
  }
}

* {
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;

  background-color: var(--color-bg-2);
}

body {
  color: var(--color-accent-3);

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 32px;
  line-height: 39px;
}

h2 {
  font-size: 24px;
  line-height: 29px;
}

h3 {
  font-size: 20px;
  line-height: 24px;
}

p {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  color: var(--accent-color);
  
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
}

/*ignore the following two they're requirements for sprint two -- itinerary user story*/
#priorEvents {
  text-decoration: line-through;
  color: #b6b6b8;
}

#currentEvents {
  background-color: #ffe0ff;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  color: #403e40;
}

hr {
  box-sizing: border-box;
  width: 100%;
  min-height: 1px;
  border: 0px;
  background: none;
  background-image: linear-gradient(90deg,transparent,var(--color-accent-2),transparent);
}

#root {
  width: 100%;
  height: 100%;
}

.svg {
  filter: invert(62%) sepia(9%) saturate(654%) hue-rotate(8deg) brightness(93%) contrast(88%);
}

.invisible {
  display: none !important;
}

.login-page {
  align-items: 'center';
  justify-content: 'center';
}

.acrylic-accent {
  background-color: var(--acrylic-accent-color-translucent);
  border-color: var(--acrylic-accent-color-translucent-dark);
  backdrop-filter: blur(16px);
}

.acrylic-white {
  background-color: var(--bg-color-translucent);
  border-color: var(--acrylic-accent-color-translucent-dark);
  backdrop-filter: blur(16px);
}

.acrylic-primary {
  background-color: var(--acrylic-primary-color-translucent);
  border-color: var(--acrylic-primary-color-translucent-dark);
  backdrop-filter: blur(16px);
}

.acrylic-success {
  background-color: var(--acrylic-success-color-translucent);
  border-color: var(--acrylic-success-color-translucent-dark);
  backdrop-filter: blur(16px);
}

.acrylic-warning {
  background-color: var(--acrylic-warning-color-translucent);
  border-color: var(--acrylic-warning-color-translucent-dark);
  backdrop-filter: blur(16px);
}

.acrylic-danger {
  background-color: var(--acrylic-danger-color-translucent);
  border-color: var(--acrylic-danger-color-translucent-dark);
  backdrop-filter: blur(16px);
}
